// import router from '@/router'
import axios from 'axios'
// 自定义判断元素类型JS
function toType(obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

// 参数过滤函数
function filterNull(o) {
    for (var key in o) {
        if (o[key] === null) {
            delete o[key]
        }
        if (toType(o[key]) === 'string') {
            o[key] = o[key].trim()
        } else if (toType(o[key]) === 'object') {
            o[key] = filterNull(o[key])
        } else if (toType(o[key]) === 'array') {
            o[key] = filterNull(o[key])
        }
    }
    return o
}

// axios 全局变量 //
// 线上地址
 axios.defaults.baseURL = 'https://api.mengtiaodong.com/'
 //axios.defaults.baseURL = 'http://192.168.2.104:8081/'
// axios.defaults.baseURL = 'http://xiangke.free.idcfengye.com/'
axios.defaults.timeout = 600000
// axios.defaults.withCredentials = true // 让ajax携带cookie

// 请求拦截器
axios.interceptors.request.use(function (config) {
    if (config.url === '/api/bank/bankList' || config.url.startsWith('/api/sms/sedCode')) {
        config.headers.common['Authorization'] = ''
    } else {
        let token = localStorage.getItem('token')
        config.headers.common['Authorization'] = token === null ? '' : token
    }
    config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    return config
}, function (error) {
    return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(function (response) {
    // let status = response.data.code
    // if (status === 401) {
    //     router.push('/Index')
    // }
    return response.data
}, function (error) {
    // let errmsg = '网络异常'
    return Promise.reject(error)
})
/**
 * 定义 get post 请求
 */
export const request = {
    get: function (url, data) {
        if (data) {
            data = filterNull(data)
        }
        let config = {
            method: 'get',
            url: url,
            params: data || {/* get请求参数 */ }
        }
        return axios(config)
    },
    post: function (url, data) {
        if (data) {
            data = filterNull(data)
        }
        let config = {
            method: 'post',
            url: url,
            data: data || {/* post请求参数 */ }
        }
        return axios(config)
    },
    multipleRequest: function (requests) {
        let reqArray = []
        if (requests.length > 0) {
            for (let req of requests) {
                if (req.method === 'GET') {
                    reqArray.push(axios.get(req.url, { params: req.data }))
                } else if (req.method === 'POST') {
                    reqArray.push(axios.post(req.url, req.data))
                }
            }
            return axios.all(reqArray)
        }
    }
}

import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(Router);

let constantRouterMap = [
    {
        path: "/",
        redirect: "/Index"
    },
    {
        path: "/Index",
        name: "首页",
        component: () => import("@/views/Index.vue")
    },
    {
        path: "/house-list",
        name: "房源",
        component: () => import("@/views/house-list.vue")
    },
    {
        path: "/house-map",
        name: "房源地图",
        component: () => import("@/views/house-map.vue")
    },
    {
        path: "/house-deal",
        name: "房源成交量",
        component: () => import("@/views/house-deal.vue")
    },
    {
        path: "/house-change",
        name: "房源变化",
        component: () => import("@/views/house-change.vue")
    },
    {
        path: "/house-detail",
        name: "房屋详情",
        component: () => import("@/views/house-detail.vue")
    },
    {
        path: '/login-register',
        name: '登录注册',
        component: () => import('@/views/login-register.vue')
    },
    {
        path: "/user-account",
        name: "个人中心",
        component: () => import("@/views/user-account.vue")
    },
    {
        path: "/401",
        name: "无权访问",
        component: () => import("@/views/errorPage/401.vue")
    },
    {
        path: "/404",
        name: "找不到页面",
        component: () => import("@/views/errorPage/404.vue")
    }
];

let router = new Router({
    routes: constantRouterMap
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
